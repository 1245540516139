import { CommandMessage, messageOutputType } from '@iegm-app/handlers';
import { types } from '@iegm-app/libs';
import { Login } from './login.message';

export class LoginPeloToken extends CommandMessage{

    name = 'command:auth/login/token'

    input = types.object({
        'token': types.string(),
    })

    output = messageOutputType(Login)

}