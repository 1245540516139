import { a } from "./chunk-RAAYCPUM.js";
function r(...t) {
  return a(T, t);
}
function T(t, n, l) {
  let e = t;
  for (let o of n) {
    if (e == null) break;
    e = e[o];
  }
  return e ?? l;
}
export { r as a };