import { HttpClient } from '@angular/common/http';
import { Injectable, Type, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Message, MessageInput, MessageOutput } from '@iegm-app/handlers';

@Injectable({
    providedIn: 'root'
})
export class ApiService{

    http = inject(HttpClient)

    protected url(path:string){
        return environment.api + '/' + path
    }

    command<T extends Message>(message:Type<T>, params:MessageInput<T>){

        return this.http.post<MessageOutput<T>>(this.url('command'), {
            'message': (new message).name,
            'input': params
        })

    }

}