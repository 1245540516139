import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ConcluirTarefa extends CommandMessage{

    name = 'command:sig/iegm/tarefa/concluir'

    input = types.object({
        'cod_tarefa': types.string().uuid(),
        'mensagem': types.string(),
    })

}