import { a as o } from "./chunk-RAAYCPUM.js";
function u(...r) {
  return o(f, r);
}
function f(r) {
  let n = [...r];
  for (let e = 0; e < r.length; e++) {
    let t = e + Math.floor(Math.random() * (r.length - e)),
      a = n[t];
    n[t] = n[e], n[e] = a;
  }
  return n;
}
export { u as a };