import { Directive, ElementRef, Input } from "@angular/core";
import { NgxCurrencyConfig, NgxCurrencyDirective } from "ngx-currency";

@Directive({
    selector: 'input[int]',
    standalone: true,
    hostDirectives: [
        {'directive':NgxCurrencyDirective, 'inputs':['options']}
    ],
})
export class InputIntDirective{

    @Input()
        set max(value:number|null|undefined){ this.ngxCurrencyDirective.options.max = value }
        get max(){ return this.ngxCurrencyDirective.options.max }

    @Input()
        set min(value:number|null|undefined){ this.ngxCurrencyDirective.options.min = value }
        get min(){ return this.ngxCurrencyDirective.options.min }

    @Input()
        set options(value:Partial<NgxCurrencyConfig>){ this.ngxCurrencyDirective.options = value }
        get options(){ return this.ngxCurrencyDirective.options }

    constructor(
        private el:ElementRef,
        private ngxCurrencyDirective:NgxCurrencyDirective
    ) {

        this.ngxCurrencyDirective.currencyMask = {
            'prefix': '',
            'suffix': '',
            'precision': 0,
            'allowZero': true,
            'decimal': '',
            'thousands': '',
            'allowNegative': true,
            'align': 'left',
            'nullable': true,
        }

    }

}
