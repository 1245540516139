import { a as t } from "./chunk-RAAYCPUM.js";
function l(...e) {
  return t(y, e);
}
function y(e, a) {
  let n = {};
  for (let [o, r] of Object.entries(e)) {
    let u = a(o, r, e);
    n[u] = r;
  }
  return n;
}
export { l as a };