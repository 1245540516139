import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ListaDeVariaveisDoQuestionarioDaEntidade extends ViewMessage{

    name = 'view:iegm/questionarios/questionario-entidade/variaveis'

    input = types.object({
        'cod_questionario_entidade': types.string().uuid(),
    })

    output = types.array(
        types.object({
            'cod_variavel': types.string().uuid(),
            'nome_variavel': types.string(),
            'descricao_variavel': types.string(),
            'valor_variavel': types.string().nullable(),
            'tipo_variavel': types.number().nullable(),
        })
    )

}
