import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ReabrirQuestaoDoQuestionarioDaEntidade extends CommandMessage{

    name = 'command:sig/iegm/preenchimento/reabrir'

    input = types.object({
        'cod_questao_questionario_entidade': types.string(),
    })

}