import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class DadosDaQuestaoDoQuestionarioDaEntidade extends ViewMessage{

    name = 'view:iegm/questionarios/questoes-questionario-entidade/dados'

    input = types.object({
        'cod_questao_questionario_entidade': types.string().uuid(),
    })

    output = types.object(ObjectOutput.shape).merge(
        types.object({
            'arquivos': types.array(
                types.object({
                    'cod_upload': types.string().uuid(),
                    'nome_arquivo': types.string(),
                })
            ),
            'comentarios': types.array(
                types.object({
                    'cod_comentario': types.string().uuid(),
                    'cod_usuario': types.string().uuid(),
                    'nome_usuario': types.string(),
                    'texto': types.string(),
                    'data': types.string(),
                })
            ),
            'historico': types.array(
                types.object({
                    'data': types.string(),
                    'status': types.number().int(),
                    'ativo': types.number().int(),
                    'descricao_status': types.string(),
                    'observacoes': types.nullable(types.string()),
                    'cod_usuario': types.string().uuid(),
                    'nome_usuario': types.string(),
                })
            ),
            'historico_respostas': types.array(
                types.object({
                    'ano': types.number().int(),
                    'cod_questao_questionario_entidade': types.string().uuid(),
                    'valor_resultado_questao': types.string().nullable(),
                    'respostas': types.array(
                        types.object({
                            'cod_opcao': types.string().uuid(),
                            'descricao_opcao': types.string(),
                            'tipo_resposta_opcao': types.number().int(),
                            'cod_opcao_questionario': types.string().uuid(),
                            'resposta': types.nullable(types.string()),
                            'maincod': types.nullable(types.string()),
                        })
                    )
                })
            ),
            'pai': types.nullable(
                ObjectOutput
            ),
            'filhos': types.array(
                ObjectOutput
            )
        })
    )

}

const ObjectOutput = types.object({
    'cod_questionario': types.string().uuid(),
    'ano_questionario': types.number().int(),
    'cod_dimensao': types.string().uuid(),
    'cod_dimensao_questionario_entidade': types.string().uuid(),
    'valor_resultado_dimensao': types.string().nullable(),
    'nome_dimensao': types.string(),
    'cod_questao_questionario_entidade': types.string().uuid(),
    'cod_questao_questionario': types.string().uuid(),
    'cod_questao': types.string().uuid(),
    'descricao_questao': types.string(),
    'numeracao_questao': types.string(),
    'cod_usuario_responsavel_resposta': types.nullable(types.string().uuid()),
    'nome_usuario_responsavel_resposta': types.nullable(types.string()),
    'cod_usuario_responsavel_questao': types.nullable(types.string().uuid()),
    'nome_usuario_responsavel_questao': types.nullable(types.string()),
    'cod_usuario_responsavel_dimensao': types.nullable(types.string().uuid()),
    'nome_usuario_responsavel_dimensao': types.nullable(types.string()),
    'cod_usuario_responsavel_status': types.nullable(types.string().uuid()),
    'nome_usuario_responsavel_status': types.nullable(types.string()),
    'status_dimensao': types.number().int(),
    'status_questionario': types.number().int(),
    'status_questao': types.nullable(types.number().int()),
    'descricao_status_questao': types.nullable(types.string()),
    'data_status_questao': types.nullable(types.string()),
    'data_resposta': types.nullable(types.string()),
    'observacoes_status_questao': types.nullable(types.string()),
    'tipo_questao': types.number().int(),
    'tem_plano_acao': types.number().int(),
    'valor_resultado_questao': types.string().nullable(),
    'opcoes': types.array(
        types.object({
            'cod_opcao': types.string().uuid(),
            'descricao_opcao': types.string(),
            'tipo_resposta_opcao': types.number().int(),
            'cod_opcao_questionario': types.string().uuid(),
            'resposta': types.nullable(types.string()),
            'maincod': types.nullable(types.string()),
        })
    )
})
