import { ViewMessage } from "@iegm-app/handlers";
import { types } from "@iegm-app/libs";

export class DadosDoLogin extends ViewMessage{

    name = 'view:sig/auth/login/dados'

    input = types.object({
        'token': types.string(),
    })

    output = types.object({
        'cod_usuario': types.string(),
        'login': types.string(),
        'nome': types.string(),
        'cod_entidade': types.string(),
    })

}