import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class DashboardDoQuestionarioDaEntidade extends ViewMessage{

    name = 'view:iegm/questionarios/dashboards/questionario'

    input = types.object({
        'cod_questionario_entidade': types.string().uuid(),
    })

    output = types.any()

}