import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class AlterarResposnaveisDasQuestoesDoQuestionariosDaEntidade extends CommandMessage{

    name = 'view:sig/iegm/questionario-entidade/questao/alterar-responsaveis'

    input = types.object({
        'cod_questao_questionario_entidade': types.array( types.string().uuid() ),
        'cod_usuario_responsavel': types.string().uuid(),
    })
    
}