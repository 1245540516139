export const status_questao_questionario_entidade = {
    'em_preparacao': 0,
    'aguardando_resposta': 1,
    'respondido': 2,
    'revisar_resposta': 3,
    'concluido': 4,
    'nao_responder': 5,
    'revisado': 6,
    'finalizado': 7,
    'importacao': 8,
}