import { a as i } from "./chunk-IEE7G5JG.js";
import { a as o, b as a } from "./chunk-ANXBDSUI.js";
function f(...e) {
  return i(y, e);
}
function y(e) {
  if (e.length === 0) return a;
  let n = new Map();
  for (let r of e) n.set(r, (n.get(r) ?? 0) + 1);
  return r => {
    let t = n.get(r);
    return t === void 0 || t === 0 ? o : (t === 1 ? n.delete(r) : n.set(r, t - 1), {
      hasNext: !0,
      next: r,
      done: n.size === 0
    });
  };
}
export { f as a };