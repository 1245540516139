import { a } from "./chunk-RAAYCPUM.js";
function x(...e) {
  return a(o, e);
}
var o = (e, n, t) => typeof e == "string" ? d([...e], n, t).join("") : d(e, n, t);
function d(e, n, t) {
  let r = [...e];
  if (Number.isNaN(n) || Number.isNaN(t)) return r;
  let s = n < 0 ? e.length + n : n,
    i = t < 0 ? e.length + t : t;
  return s < 0 || s > e.length || i < 0 || i > e.length || (r[s] = e[i], r[i] = e[s]), r;
}
export { x as a };