export * from './dimensao-questionario-entidade/alterar-dados-dimensao-questionario-entidade'
export * from './dimensao-questionario-entidade/concluir-dimensao-questionario-entidade'
export * from './dimensao-questionario-entidade/enviar-dimensao-questionario-entidade-revisao'
export * from './dimensao-questionario-entidade/finalizar-dimensao-questionario-entidade'
export * from './dimensao-questionario-entidade/estornar-finalizacao-dimensao-questionario-entidade'
export * from './dimensao-questionario-entidade/estornar-conclusao-dimensao-questionario-entidade'
export * from './questao-questionario-entidade/alterar-dados-questao-questionario-entidade'
export * from './questao-questionario-entidade/enviar-questao-questionario-entidade-revisar-resposta'
export * from './questao-questionario-entidade/estornar-validacao-questao-questionario-entidade'
export * from './questao-questionario-entidade/marcar-questao-questionario-entidade-revisada'
export * from './questao-questionario-entidade/estornar-pedido-revisao-questao-questionario-entidade'
export * from './operacoes/iniciar-questionario-entidade'
export * from './operacoes/iniciar-preenchimento-questionario-entidade'
export * from './operacoes/estornar-preenchimento-questionario-entidade'
export * from './operacoes/concluir-questionario-entidade'
export * from './operacoes/estornar-conclusao-questionario-entidade'
export * from './operacoes/resetar-questionario-entidade'
export * from './operacoes/corrigir-questionario-entidade'
export * from './operacoes/recalcular-notas-questionario-entidade'
export * from './operacoes/responder-questionario-aleatoriamente'
export * from './questao-questionario-entidade/alterar-responsaveis-questoes-questionario-entidade'
export * from './dimensao-questionario-entidade/executar-calculo-valor-dimensao-questionario-entidade'
export * from './questionario-entidade/salvar-lista-variaveis-questionario-entidade'
export * from './operacoes/atribuir-todas-responsabilidades-usuario-logado'
