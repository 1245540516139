import { ComponentRef, EnvironmentInjector, EventEmitter, inject, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { uuid, UUIDString } from '@iegm-app/core';
import { first, Observable, tap } from 'rxjs';
import { MessageComponent } from '../../components/messages/message.component';
import { MessagesContainerComponent } from '../../components/messages/messages-container.component';

@Injectable({
    providedIn: 'root',
})
export class MessagesControllerService {

    protected rootViewContainerRef!:ViewContainerRef
    protected container?:ComponentRef<MessagesContainerComponent>
    protected environmentInjector = inject(EnvironmentInjector)
    protected injector = inject(Injector)
    protected timeout?:NodeJS.Timeout

    readonly messages:Array<MessagesItemParameters> = []
    readonly events = new EventEmitter<{event:'created'|'closed', id:UUIDString, component?:MessageComponent}>()

    initialize(viewContainerRef:ViewContainerRef){
        this.rootViewContainerRef = viewContainerRef
    }

    show(properties:MessageProperties){
        this.create(properties).subscribe()
    }

    create(properties:MessageProperties){

        if( !this.container ){
            this.container = this.rootViewContainerRef.createComponent(MessagesContainerComponent)
        }

        const message_uuid = uuid()
        clearTimeout(this.timeout)

        return new Observable<MessageComponent>((subscriber) => {

            this.events.pipe(
                first(e => e.event === 'created' && e.id === message_uuid),
                tap(e => {
                    subscriber.next(e.component)
                    subscriber.complete()
                }),
            ).subscribe()

            this.messages.push({
                'id': message_uuid,
                'properties': properties,
            })

        })

    }

    close(id:UUIDString){

        const modal = this.messages.find(m => m.id === id)

        if( !modal ){
            return
        }

        this.messages.splice(this.messages.indexOf(modal), 1)
        this.onMessageClosed()

    }

    protected onMessageCreated(component:ComponentRef<any>){
    }

    protected onMessageClosed(){

        if( this.messages.length === 0 ){

            if( this.timeout ){
                clearTimeout(this.timeout)
            }

            this.timeout = setTimeout(() => {
                this.container?.destroy()
                this.container = undefined
            }, 250)

        }

    }

}

export type MessagesItemParameters = {
    id:UUIDString
    properties:MessageProperties
}

export type MessageProperties = {
    title?:string
    message?:string
    color?:'is-success'|'is-warning'|'is-danger'|'is-info'|'is-primary'|'is-dark'
    timeout?:number
}
