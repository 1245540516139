import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ListaDeDimensoesDoQuestionarioDaEntidade extends ViewMessage{

    name = 'view:iegm/questionarios/dimensoes-questionario-entidade/lista'

    input = types.object({
        'cod_questionario_entidade': types.string().uuid(),
    })

    output = types.array(
        types.object({
            'cod_dimensao': types.string(),
            'cod_dimensao_questionario_entidade': types.nullable(types.string()),
            'nome_dimensao': types.string(),
            'status_dimensao': types.nullable(types.number()),
            'descricao_status_dimensao': types.nullable(types.string()),
            'cod_usuario_responsavel_dimensao': types.nullable(types.string()),
            'nome_usuario_responsavel_dimensao': types.nullable(types.string()),
            'dimensao_configurada': types.number(),
            'todas_questoes_concluidas': types.number(),
            'todas_questoes_validadas': types.number(),
            'total_questoes': types.number(),
            'total_questoes_disponiveis': types.number(),
            'total_questoes_validadas': types.number(),
            'porcentagem_conclusao': types.number(),
            'porcentagem_validado': types.number(),
            'porcentagem_responsaveis_configurados': types.number(),
            'total_responsaveis_configurados': types.number(),
            'total_responsaveis_nao_configurados': types.number(),
            'valor_resultado_dimensao': types.string().nullable(),
            'valor_resultado_dimensao_ano_anterior': types.string().nullable(),
        })
    )

}
