function y(l, {
  waitMs: u,
  timing: a = "trailing",
  maxWaitMs: t
}) {
  if (t !== void 0 && u !== void 0 && t < u) throw new Error(`debounce: maxWaitMs (${t}) cannot be less than waitMs (${u})`);
  let n,
    d,
    o,
    i,
    f = () => {
      if (d !== void 0) {
        let r = d;
        d = void 0, clearTimeout(r);
      }
      if (o === void 0) throw new Error("REMEDA[debounce]: latestCallArgs was unexpectedly undefined.");
      let e = o;
      o = void 0, i = l(...e);
    },
    s = () => {
      if (n === void 0) return;
      let e = n;
      n = void 0, clearTimeout(e), o !== void 0 && f();
    },
    c = e => {
      o = e, t !== void 0 && d === void 0 && (d = setTimeout(f, t));
    };
  return {
    call: (...e) => {
      if (n === void 0) a === "trailing" ? c(e) : i = l(...e);else {
        a !== "leading" && c(e);
        let r = n;
        n = void 0, clearTimeout(r);
      }
      return n = setTimeout(s, u ?? t ?? 0), i;
    },
    cancel: () => {
      if (n !== void 0) {
        let e = n;
        n = void 0, clearTimeout(e);
      }
      if (d !== void 0) {
        let e = d;
        d = void 0, clearTimeout(e);
      }
      o = void 0;
    },
    flush: () => (s(), i),
    get isPending() {
      return n !== void 0;
    },
    get cachedValue() {
      return i;
    }
  };
}
export { y as a };