import { a as n, c as o } from "./chunk-ANXBDSUI.js";
import { a as t } from "./chunk-RAAYCPUM.js";
function i(...e) {
  return t(a, e, u);
}
var a = (e, r) => r < 0 ? [...e] : e.slice(r);
function u(e) {
  if (e <= 0) return o;
  let r = e;
  return p => r > 0 ? (r -= 1, n) : {
    done: !1,
    hasNext: !0,
    next: p
  };
}
export { i as a };