import { HttpClient } from '@angular/common/http';
import { Type } from '@angular/core';
import { Message, MessageInput, MessageOutput } from '@iegm-app/handlers';
import { environment } from '../../../environments/environment';
import { of, tap } from 'rxjs';
import { UUIDString } from '@iegm-app/core';

export class ApiInstance{

    constructor(
        protected params:{
            'http':HttpClient,
            'loading'?:boolean
        }
    ){
    }

    protected url(path:string){
        return environment.api + '/' + path
    }

    command<T extends Message>(message:Type<T>, params:MessageInput<T>){

        return this.params.http.post<MessageOutput<T>>(this.url('command'), {
            'message': (new message).name,
            'input': params,
            '__loading': this.params.loading ?? true
        })

    }

    upload(relacionamento:string, key:UUIDString, arquivos:Array<{file:File, relativePath:string}>){

        if( arquivos.length === 0 ){
            return of(null)
        }

        const formData = new FormData()
        for (const [index, arquivo] of arquivos.entries()) {
            formData.append(`upload[${index}]`, arquivo.file, arquivo.relativePath)
        }

        return this.params.http.post(this.url(`upload/${relacionamento}/${key}`), formData)
    }

    //
    //

    get http(){
        return this.params.http
    }

}