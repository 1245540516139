export const ativo_inativo = {
  'ativo': 1,
  'inativo': 0,
}

export const sim_nao = {
  'sim': 1,
  'nao': 0,
}

export const verdadeiro_falso = {
  'verdadeiro': 1,
  'falso': 0,
}