import { a as d } from "./chunk-RAAYCPUM.js";
function o(...e) {
  return d(t, e);
}
var t = (e, r) => {
  for (let n = e.length - 1; n >= 0; n--) {
    let a = e[n];
    if (r(a, n, e)) return a;
  }
};
export { o as a };