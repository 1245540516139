import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class EnviarQuestaoDoQuestionarioDaEntidadeParaRevisarResposta extends CommandMessage{

    name = 'command:sig/iegm/estrutura/revisar-resposta'

    input = types.object({
        'cod_questao_questionario_entidade': types.string().uuid(),
        'motivo': types.string(),
        'reabrir_dimensao': types.optional(types.number().int())
    })

}