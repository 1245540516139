import { a } from "./chunk-IEE7G5JG.js";
import { a as n } from "./chunk-ANXBDSUI.js";
function l(...e) {
  return a(i, e);
}
var i = e => (t, r, o) => o.findIndex(u => e(t, u)) === r ? {
  done: !1,
  hasNext: !0,
  next: t
} : n;
export { l as a };