import { a as r } from "./chunk-IEE7G5JG.js";
function p(...e) {
  return r(l, e);
}
var l = (e, n) => {
  let a = n;
  return (t, u, o) => (a = e(a, t, u, o), {
    done: !1,
    hasNext: !0,
    next: a
  });
};
export { p as a };