import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ExecutarCalculoValorDaDimensaoDoQuestionarioDaEntidade extends CommandMessage{

    name = 'command:sig/iegm/estrutura/dimensao-questionario-entidade/calcular'

    input = types.object({
        'cod_dimensao_questionario_entidade': types.string().uuid(),
        'assigns': types.any().optional()
    })
    
    output = types.object({
        'valor': types.string().nullable(),
    })

}