import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class AlterarDadosDaDimensaoDoQuestionariosDaEntidade extends CommandMessage{

    name = 'view:sig/iegm/questionario-entidade/dimensao/alterar'

    input = types.object({
        'cod_dimensao_questionario_entidade': types.string().uuid(),
        'cod_usuario_responsavel': types.optional(types.string().uuid()),
    })
    
}