import { inject, Injectable } from '@angular/core';
import { GlobalModalControllerService } from '../../services/modal/global-modal-controller.service';
import { AlertComponent } from './alert.component';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AlertsService {
    
    modalController = inject(GlobalModalControllerService)

    constructor(){

    }

    create(properties:AlertParameters){

        return this.modalController.open(AlertComponent, {
            'title': properties.title ?? '',
            'size': 'is-small',
            'is_closeable': false,
            'inputs': {properties}
        })

    }

    confirm(title:string, message:string){

        return new Observable<boolean>((subscriber) => {
            
            this.create({
                'title': title,
                'message': message,
                'buttons':[
                    {
                        'label':'Sim',
                        'class': 'is-primary',
                        'action':() => ( subscriber.next(true), subscriber.complete() )
                    },
                    {
                        'label':'Não',
                        'action':() => ( subscriber.complete() )
                    },
                ]
            }).subscribe()

        })

    }

}

export type AlertParameters = {
    title?:string
    message:string
    class?:'is-info'|'is-warning'|'is-danger'|'is-success'
    size?:'is-small'|'is-medium'|'is-large'
    icon?:string
    is_closeable?:boolean
    buttons?:Array<{
        label:string
        action:() => void
        class?:'is-info'|'is-warning'|'is-danger'|'is-success'|'is-primary'
    }>
}