import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class DadosDaProvidenciaDaTarefa extends ViewMessage{

    name = 'view:sig/tarefas/providencia/dados'

    input = types.object({
        'cod_providencia': types.string()
    })

    output = types.object({
        'cod_providencia': types.string(),
        'texto_providencia': types.string(),
        'cod_usuario_responsavel': types.string(),
        'nome_usuario_responsavel': types.string(),
        'data_providencia': types.string(),
    })

}
