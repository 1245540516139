<div [ngClass]="[class]" class="frame banner">

    <div class="frame-content overlay">
        
        <div class="is-flex">
            
            @if(icon){
                <div class="banner-icon pr-3">
                    <i [class]="icon"></i>
                </div>
            }

            <div class="is-flex-grow-1 banner-content">

                @if( message ){
                    <div>
                        {{message}}
                    </div>
                }

                <ng-content></ng-content>

            </div>

        </div>

    </div>

</div>