import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UUIDString } from '@iegm-app/core';
import { commands } from '@iegm-app/iegm-messages';
import { switchMap, tap } from 'rxjs';
import { api, dialogs, messages } from '../../../../facades';
import { ModalService } from '../../../modal/modal.service';
import { DownloadDirective } from '../../../../directives/download/download.directive';

@Component({
    standalone: true,
    imports: [CommonModule, DownloadDirective],
    selector: 'input-upload-visualizacao',
    templateUrl: './input-upload-visualizacao.component.html',
})
export class InputUploadVisualizacaoComponent implements OnInit{

    @Output() alterado = new EventEmitter<Array<{cod_upload:UUIDString, nome_arquivo:string}>>()
    @Input() arquivos = new Array<{cod_upload:UUIDString, nome_arquivo:string}>()
    @Input() tipo_upload!:string
    @Input() pode_excluir = true

    modal = inject(ModalService)

    ngOnInit(){

    }

    concluir(){
    }

    removerAnexo(cod_upload:UUIDString){
        
        dialogs().confirm('Atenção', 'Esta certo de excluir o arquivo ?').pipe(
            switchMap(() => api().command(commands.comum.ApagarUpload, {
                'tipo_upload': this.tipo_upload,
                cod_upload,
            })),
            tap(() => messages().show({'title':'Sucesso', 'message':'Arquivo excluído com sucesso', 'color':'is-success'})),
            tap(() => {
                const index = this.arquivos.indexOf(this.arquivos.find(x => x.cod_upload === cod_upload)!)
                this.arquivos.splice(index, 1)
            }),
            tap(() => this.alterado.emit(this.arquivos)),
            tap(() => {
                
                if( this.arquivos.length === 0 ){
                    this.modal.close()
                }

            })
        ).subscribe()

    }

}
