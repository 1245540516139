import { messageOutputType, ViewMessage } from "@iegm-app/handlers"
import { views } from "../../../.."
import { types } from "@iegm-app/libs"

export class DadosDaQuestao extends ViewMessage{

    name = 'view:sig/iegm/preenchimento/dados'

    input = types.object({
        'cod_questao_questionario_entidade': types.string(),
        'cod_usuario': types.string(),
    })

    output = messageOutputType(views.iegm.questionarios.DadosDaQuestaoDoQuestionarioDaEntidade).omit({'historico':true})

}