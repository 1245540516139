function a(n, t, e) {
  return n(e[0]) ? r => t(r, ...e) : t(...e);
}
var x = Object.assign(u, {
  defaultCase: R
});
function u(...n) {
  return a(s, o, n);
}
function o(n, ...t) {
  for (let [e, r] of t) if (e(n)) return r(n);
  throw new Error("conditional: data failed for all cases");
}
function s(n) {
  if (!Array.isArray(n)) return !1;
  let [t, e, ...r] = n;
  return typeof t == "function" && t.length <= 1 && typeof e == "function" && e.length <= 1 && r.length === 0;
}
function R(n = d) {
  return [T, n];
}
var T = () => !0,
  d = () => {};
export { x as a };