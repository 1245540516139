import { EventEmitter, inject, Injectable, Output } from '@angular/core';
import { ModalComponent } from './modal.component';
import { GlobalModalControllerService } from '../../services/modal/global-modal-controller.service';

@Injectable()
export class ModalService {

    @Output() closed = new EventEmitter()

    controller = inject(GlobalModalControllerService)
    instance!:ModalComponent

    constructor() {
    }

    initialize(modalInstance:ModalComponent){
        this.instance = modalInstance
    }

    close(){
        this.controller.close(this.instance.id)
        this.closed.emit()
    }

    get id(){
        return this.instance.id
    }

}