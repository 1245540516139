import { a as r } from "./chunk-IEE7G5JG.js";
import { a as n } from "./chunk-ANXBDSUI.js";
function i(...e) {
  return r(o, e);
}
function o() {
  let e = new Set();
  return t => e.has(t) ? n : (e.add(t), {
    done: !1,
    hasNext: !0,
    next: t
  });
}
export { i as a };