import { a as d } from "./chunk-SGAFZVQH.js";
import { a as r } from "./chunk-ANXBDSUI.js";
import { a } from "./chunk-RAAYCPUM.js";
function f(...e) {
  return a(i, e, d(u));
}
var i = (e, n) => e.find(n),
  u = e => (n, t, o) => e(n, t, o) ? {
    done: !0,
    hasNext: !0,
    next: n
  } : r;
export { f as a };