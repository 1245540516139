import { CommonModule, NgComponentOutlet } from '@angular/common';
import { Component, inject, OnInit, ViewContainerRef } from '@angular/core';
import { MessagesControllerService } from '../../services/messages/messages-controller.service';
import { MessageComponent } from './message.component';

@Component({
    standalone: true,
    imports: [CommonModule, NgComponentOutlet],
    selector: 'messages-container',
    template: `

        <div class="messages-container">

            @for (message of messagesController.messages; track $index) {

                <ng-container *ngComponentOutlet="
                    messageTemplateComponent;
                    'inputs':{
                        'id': message.id,
                        'index': $index,
                        'properties': message.properties,
                    }
                "></ng-container>

            }

        </div>

    `,
})
export class MessagesContainerComponent implements OnInit{

    messageTemplateComponent = MessageComponent
    viewContainerRef = inject(ViewContainerRef)

    constructor(
        readonly messagesController:MessagesControllerService
    ){
    }

    ngOnInit(){
    }

}
