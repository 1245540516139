import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ListaDeTarefas extends ViewMessage{

    name = 'view:sig/tarefas/lista'

    input = types.object({
        'cod_entidade': types.string().optional(),
        'inbox': types.string().optional(),
        //
        'status_tarefa': types.array(types.number().int()).optional(),
        'cod_usuario_responsavel_tarefa': types.array(types.string()).optional(),
        'cod_usuario_responsavel_tramite': types.array(types.string()).optional(),
        'busca': types.string().optional(),
        'data_criacao_de': types.string().optional(),
        'data_criacao_ate': types.string().optional(),
    })

    output = types.array(
        types.object({
            'cod_tarefa': types.string(),
            'maincod_tarefa': types.string(),
            'cod_entidade': types.string(),
            'cod_usuario_criador': types.string(),
            'nome_usuario_criador': types.string(),
            'assunto': types.string(),
            'tem_prazo': types.number().int(),
            'data_prazo': types.string().nullable(),
            'cod_usuario_responsavel_tramite': types.string(),
            'nome_usuario_responsavel_tramite': types.string(),
            'data_tramite': types.string(),
            'status_tarefa': types.number().int(),
            'tipo_tarefa': types.number().int(),
        })
    )

}
