import { a as o } from "./chunk-IEE7G5JG.js";
import { a } from "./chunk-ANXBDSUI.js";
function p(...e) {
  return o(d, e);
}
function d(e) {
  let n = new Set();
  return (t, u, i) => {
    let r = e(t, u, i);
    return n.has(r) ? a : (n.add(r), {
      done: !1,
      hasNext: !0,
      next: t
    });
  };
}
export { p as a };