import { c as r } from "./chunk-ANXBDSUI.js";
import { a as n } from "./chunk-K26VP6CL.js";
function y(e, t) {
  return typeof e == "object" ? a(e, t) : n(a, e === void 0 ? [] : [e], o);
}
var a = (e, t) => t === void 0 ? e.flat() : e.flat(t),
  o = e => e === void 0 || e === 1 ? l : e <= 0 ? r : t => Array.isArray(t) ? {
    next: t.flat(e - 1),
    hasNext: !0,
    hasMany: !0,
    done: !1
  } : {
    next: t,
    hasNext: !0,
    done: !1
  },
  l = e => Array.isArray(e) ? {
    next: e,
    hasNext: !0,
    hasMany: !0,
    done: !1
  } : {
    next: e,
    hasNext: !0,
    done: !1
  };
export { y as a };