import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    'name': 'ucfirst',
    'standalone': true,
})
export class UCFirstCasePipe implements PipeTransform {

    transform(value: string): string;
    transform(value: null | undefined): null;
    transform(value: string | null | undefined): string | null;
    transform(value: string | null | undefined): string | null {

        if (value == null) return null
        if (typeof value !== 'string') return null

        return value[0].toUpperCase() + value.slice(1).toLowerCase()

    }

}