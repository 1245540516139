<div class="modal-window-container" [ngStyle]="{
    '--modal-index': index,
    '--modal-z-index': (service.controller.modals.length - index - 1),
}">

    <div class="modal-window" [attr.size]="properties.size" [ngClass]="{'closed':is_closing}">

        @if( properties.title !== false ){

            <div class="modal-window-title">
                <div class="modal-window-title-label">
                    {{properties.title}}
                </div>
                <div class="modal-window-title-buttons">
                    @if( properties.is_closeable !== false ){
                        <button class="button" (click)="close()">X</button>
                    }
                </div>
            </div>
            
        }

        <div class="modal-window-content">

            <!--
            tivemos de utilizar o componente ndc-dynamic (https://github.com/gund/ng-dynamic-component)
            pois o angular padrão não da acesso ao componente criado atraves do ngComponentOutlet

            https://github.com/angular/angular/issues/15360
            https://github.com/angular/angular/issues/14842

            <ng-container #instance *ngComponentOutlet="template; "></ng-container>
            -->

            <div class="modal-window-content-page">

                <ndc-dynamic 
                    [ndcDynamicComponent]="template" 
                    [ndcDynamicInjector]="properties.injector"
                    [ndcDynamicInputs]="properties.inputs"
                    [ndcDynamicEnvironmentInjector]="properties.enviromentInjector"
                    [ndcDynamicProviders]="providers"
                    (ndcDynamicCreated)="componentCreated($event)"
                ></ndc-dynamic>

            </div>

        </div>

    </div>

</div>
