import { a as r } from "./chunk-RAAYCPUM.js";
function l(...n) {
  return r(d, n);
}
function d(n, o) {
  let e = {};
  for (let [a, t] of n.entries()) {
    let [y, u] = o(t, a, n);
    e[y] = u;
  }
  return e;
}
export { l as a };