import { CommonModule } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, ComponentRef, EnvironmentInjector, inject, Injector, Input, OnInit } from '@angular/core';
import { UUIDString } from '@iegm-app/core';
import { DynamicModule } from 'ng-dynamic-component';
import { GlobalModalControllerService, ModalProperties } from '../../services/modal/global-modal-controller.service';
import { ModalService } from './modal.service';
import { ResolvedDataService } from '../../../libs/resolver/resolved-data.service';

@Component({
    standalone: true,
    imports: [CommonModule, DynamicModule],
    selector: 'modal',
    templateUrl: './modal.component.html',
    providers: [ModalService],
})
export class ModalComponent implements OnInit, AfterViewInit, AfterContentInit{

    protected is_closing = false

    controller = inject(GlobalModalControllerService)
    service = inject(ModalService)
    injector = inject(Injector)
    enviromentInjector = inject(EnvironmentInjector)
    providers = [
        {'provide':ModalService, 'useValue':this.service},
    ]

    @Input() template!:any
    @Input() inputs:any
    @Input() id!:UUIDString
    @Input() index!:number
    @Input() properties!:ModalProperties

    constructor(
    ){
        this.service.initialize(this)
    }

    ngOnInit(){
    }

    ngAfterViewInit(){
    }

    ngAfterContentInit(){
    }

    componentCreated(component:ComponentRef<any>){
        this.controller.events.emit({'event':'created', 'id':this.id, 'component':component})
    }

    close(){

        this.is_closing = true

        setTimeout(() => {
            this.service.close()
        }, 250)

    }

}
