import { computed, inject, Injector, Signal, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { r } from '@iegm-app/libs';
import { Observable, tap } from 'rxjs';
import { ResolverOutput } from '.';
import { ModalService } from '../../app/components/modal/modal.service';

export class ResolvedDataService<T extends (...params: any) => Observable<any> = any>{

    protected injector = inject(Injector)
    protected resolver!:T
    protected signals:MockToWritableSignal<ResolverOutput<T>> = {} as any
    value:MockToSignal<ResolverOutput<T>> = {} as any

    constructor() {

        const route = this.injector.get(ActivatedRoute)
        const modal = this.injector.get(ModalService, null)

        const values = modal?.instance.properties.resolver?.value ?? route.snapshot.data['data']

        for (const [key, value] of Object.entries(values)) {

            (this.signals[key] as any) = signal(value) as any
            (this.value[key] as any) = computed(() => {
                return this.signals[key]()
            })

        }

    }

    create(resolver:T){
        this.resolver = resolver
        return this
    }

    refresh(){

        const resolver = this.isModal() ? this.injector.get(ModalService).instance.properties.resolver?.fn : this.resolver
        const resolver_params = this.isModal() ? this.injector.get(ModalService).instance.properties.resolver?.params : [this.injector.get(ActivatedRoute).snapshot]

        return resolver(...resolver_params).pipe(
            tap((values:Record<string, any>) => {

                for (const [key, data] of Object.entries(values)) {

                    //if( !r.isDeepEqual(this.signals[key](), data) ){
                        //this.signals[key].update(x => data);
                        this.signals[key].set(data);
                        //Object.assign(this.value, {[key]: computed(() => this.signals[key]())})
                    //}

                }

            }),
        )

    }

    update(data:Record<string, any>){
        
        for (const [key, value] of Object.entries(data)) {

            if( !r.isDeepEqual(this.signals[key](), value) ){

                this.signals[key].set(value);
                Object.assign(this.value, {[key]: computed(() => this.signals[key]())})

            }

        }

    }

    protected isModal(){
        return this.injector.get(ModalService, null) !== null ? true : false
    }

}

export function resolved<
    T extends (...params: any) => Observable<any>
>(fn:T) : ResolvedDataService<T>{
    return inject(ResolvedDataService).create(fn)
}

type MockToWritableSignal<T> = {
    [Property in keyof T]: WritableSignal<T[Property]>;
}

type MockToSignal<T> = {
    [Property in keyof T]: Signal<T[Property]>;
}
