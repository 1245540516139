import { a as s } from "./chunk-ANXBDSUI.js";
function x(t, ...o) {
  let n = t,
    u = o.map(e => "lazy" in e ? f(e) : void 0),
    p = 0;
  for (; p < o.length;) {
    if (u[p] === void 0 || !B(n)) {
      let i = o[p];
      n = i(n), p += 1;
      continue;
    }
    let r = [];
    for (let i = p; i < o.length; i++) {
      let l = u[i];
      if (l === void 0 || (r.push(l), l.isSingle)) break;
    }
    let a = [];
    for (let i of n) if (A(i, a, r)) break;
    let {
      isSingle: y
    } = r.at(-1);
    n = y ? a[0] : a, p += r.length;
  }
  return n;
}
function A(t, o, n) {
  if (n.length === 0) return o.push(t), !1;
  let u = t,
    p = s,
    e = !1;
  for (let [r, a] of n.entries()) {
    let {
      index: y,
      items: i
    } = a;
    if (i.push(u), p = a(u, y, i), a.index += 1, p.hasNext) {
      if (p.hasMany ?? !1) {
        for (let l of p.next) if (A(l, o, n.slice(r + 1))) return !0;
        return e;
      }
      u = p.next;
    }
    if (!p.hasNext) break;
    p.done && (e = !0);
  }
  return p.hasNext && o.push(u), e;
}
function f(t) {
  let {
      lazy: o,
      lazyArgs: n
    } = t,
    u = o(...n);
  return Object.assign(u, {
    isSingle: o.single ?? !1,
    index: 0,
    items: []
  });
}
function B(t) {
  return typeof t == "string" || typeof t == "object" && t !== null && Symbol.iterator in t;
}
export { x as a };