import { a } from "./chunk-RAAYCPUM.js";
function u(...e) {
  return a(o, e, i);
}
function o(e, r) {
  return e.forEach(r), e;
}
var i = e => (r, n, t) => (e(r, n, t), {
  done: !1,
  hasNext: !0,
  next: r
});
export { u as a };