import { a as r } from "./chunk-RAAYCPUM.js";
function y(...n) {
  return r(m, n);
}
var m = (n, t) => {
  if (n.length === 0) return Number.NaN;
  let e = 0;
  for (let [a, u] of n.entries()) e += t(u, a, n);
  return e / n.length;
};
export { y as a };