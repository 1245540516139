import { a as r } from "./chunk-RAAYCPUM.js";
function d(...n) {
  return r(t, n);
}
var t = (n, a) => {
  let e = 0;
  for (let [u, o] of n.entries()) {
    let m = a(o, u, n);
    e += m;
  }
  return e;
};
export { d as a };