import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ListaDeQuestionariosDisponiveisNaEntidade extends ViewMessage{

    name = 'view:iegm/questionarios/questionario-entidade/lista'

    input = types.object({
        'cod_entidade': types.string().uuid(),
    })

    output = types.array(
        types.object({
            'cod_questionario': types.string().uuid(),
            'nome_questionario': types.string(),
            'cod_questionario_entidade': types.nullable(types.string().uuid()),
            'status_questionario_entidade': types.nullable(types.number().int()),
            'descricao_status_questionario_entidade': types.nullable(types.string()),
            'cod_responsavel': types.nullable(types.string()),
            'nome_responsavel': types.nullable(types.string()),
        })
    )

}