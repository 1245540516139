import { Component, inject, OnInit, ViewContainerRef } from '@angular/core';
import { GlobalModalControllerService } from '../../services/modal/global-modal-controller.service';
import { NgComponentOutlet } from '@angular/common';
import { ModalComponent } from './modal.component';

@Component({
    standalone: true,
    imports: [NgComponentOutlet],
    selector: 'modal-container',
    template: `

        <div class="modal-container">

            @for (modal of modalController.modals; track $index) {

                <ng-container *ngComponentOutlet="
                    modalTemplateComponent;
                    'inputs':{
                        'template': modal.template,
                        'properties': modal.properties,
                        'inputs': modal.inputs,
                        'id': modal.id,
                        'index': $index,
                    }
                "></ng-container>

            }

        </div>

    `,
})
export class ModalContainerComponent implements OnInit{

    modalTemplateComponent = ModalComponent
    viewContainerRef = inject(ViewContainerRef)

    constructor(
        readonly modalController:GlobalModalControllerService
    ){
    }

    ngOnInit(){
    }

}
