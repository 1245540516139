import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class DadosDoUsuario extends ViewMessage{

    name = 'view:sig/iegm/entidade/usuarios/dados'

    input = types.object({
        'cod_usuario': types.string().uuid(),
    })

    output = types.object({
        'cod_entidade': types.string().uuid(),
        'cod_usuario': types.string().uuid(),
        'nome_usuario': types.string(),
        'responsavel_iegm': types.number().int(),
        'login': types.string(),
        'funcionalidades': types.array(
            types.string()
        )
    })

}