import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UUIDString } from '@iegm-app/core';
import { ModalControllerService } from '../../../../services/modal/modal-controller.service';
import { InputUploadVisualizacaoComponent } from '../input-upload-visualizacao/input-upload-visualizacao.component';
import { combineLatest, switchMap, tap } from 'rxjs';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'input-upload-control',
    templateUrl: './input-upload-control.component.html',
    providers: []
})
export class InputUploadControlComponent implements OnInit{

    modalController = inject(ModalControllerService)

    @Input() arquivos = new Array<{cod_upload:UUIDString, nome_arquivo:string}>()
    @Input() tipo_upload!:string
    @Input() pode_excluir = true

    @Output() alterado = new EventEmitter()

    ngOnInit(){

    }

    visualizarArquivos(){

        this.modalController.open(
            InputUploadVisualizacaoComponent,
            {
                'title': 'Arquivos anexados',
                'size': 'offcanvas',
                'inputs': {
                    'arquivos': this.arquivos,
                    'tipo_upload': this.tipo_upload,
                    'pode_excluir': this.pode_excluir,
                }
            }
        ).pipe(
            switchMap(component => combineLatest([
                component.modal.closed,
                component.alterado,
            ])),
            tap(([undefined, arquivos]) => this.arquivos = arquivos),
        ).subscribe()

    }

}
