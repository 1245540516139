import { a as n } from "./chunk-ANXBDSUI.js";
import { a as r } from "./chunk-RAAYCPUM.js";
function T(...a) {
  return r(l, a, y);
}
var l = (a, e) => a.filter(e),
  y = a => (e, t, o) => a(e, t, o) ? {
    done: !1,
    hasNext: !0,
    next: e
  } : n;
export { T as a };