// editar providencia de tarefa
import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class EditarProvidenciaTarefa extends CommandMessage{

    name = 'command:sig/iegm/tarefa/editar-providencia'

    input = types.object({
        'cod_providencia': types.string().uuid(),
        'texto': types.string(),
    })

}