import { a as i } from "./chunk-RAAYCPUM.js";
function p(...n) {
  return i(u, n);
}
function u(n, t = [], r = []) {
  if (typeof n == "function") return n;
  if (typeof n != "object" || n === null || n instanceof Date || n instanceof RegExp) return structuredClone(n);
  let o = t.indexOf(n);
  return o >= 0 ? r[o] : (t.push(n), Array.isArray(n) ? y(n, t, r) : s(n, t, r));
}
function s(n, t, r) {
  let o = {};
  r.push(o);
  for (let [e, c] of Object.entries(n)) o[e] = u(c, t, r);
  return o;
}
function y(n, t, r) {
  let o = [];
  r.push(o);
  for (let [e, c] of n.entries()) o[e] = u(c, t, r);
  return o;
}
export { p as a };