import { messageOutputType, ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"
import { DadosDaQuestaoDoQuestionarioDaEntidade } from "../../iegm/questionarios/entrypoint"

export class DadosDaTarefa extends ViewMessage{

    name = 'view:sig/tarefas/dados'

    input = types.object({
        'cod_tarefa': types.string()
    })

    output = types.object({
        'cod_tarefa': types.string(),
        'maincod_tarefa': types.string(),
        'tipo_tarefa': types.number().int(),
        'cod_entidade': types.string(),
        'cod_usuario_criador': types.string(),
        'nome_usuario_criador': types.string(),
        'assunto': types.string(),
        'descricao': types.string(),
        'tem_prazo': types.number().int(),
        'data_prazo': types.string().nullable(),
        'cod_usuario_responsavel_tramite': types.string(),
        'nome_usuario_responsavel_tramite': types.string(),
        'data_tramite': types.string(),
        'mensagem_tramite': types.string().nullable(),
        'status_tarefa': types.number().int(),
        'data_status': types.string(),
        'mensagem_status': types.string().nullable(),
        'cod_usuario_responsavel_status': types.string(),
        'nome_usuario_responsavel_status': types.string(),
        'plano_acao': messageOutputType(DadosDaQuestaoDoQuestionarioDaEntidade).optional(),
        'tramites': types.array(
            types.object({
                'cod_tramite': types.string(),
                'cod_usuario_responsavel': types.string(),
                'nome_usuario_responsavel': types.string(),
                'mensagem': types.string().nullable(),
                'data_tramite': types.string(),
                'tramite_ativo': types.number().int(),
                'usuarios_credenciados': types.array(
                    types.object({
                        'cod_usuario': types.string(),
                        'nome_usuario': types.string(),
                        'tipo_usuario': types.number().int(),
                    })
                ),
                'anexos' : types.array(
                    types.object({
                        'cod_upload': types.string(),
                        'nome_arquivo': types.string(),
                    })
                ),
                'providencias': types.array(
                    types.object({
                        'cod_providencia': types.string(),
                        'texto_providencia': types.string(),
                        'cod_usuario_responsavel': types.string(),
                        'nome_usuario_responsavel': types.string(),
                        'data_providencia': types.string(),
                        'anexos': types.array(
                            types.object({
                                'cod_upload': types.string(),
                                'nome_arquivo': types.string(),
                            })
                        ),
                    })
                ),
            })
        ),
        'tarefa_pai': types.object({
            'cod_tarefa': types.string(),
            'maincod_tarefa': types.string(),
            'cod_usuario_criador': types.string(),
            'nome_usuario_criador': types.string(),
            'assunto': types.string(),
            'tem_prazo': types.number().int(),
            'data_prazo': types.string().nullable(),
            'status_tarefa': types.number().int(),
        }).nullable(),
        'subtarefas': types.array(
            types.object({
                'cod_tarefa': types.string(),
                'maincod_tarefa': types.string(),
                'cod_usuario_criador': types.string(),
                'nome_usuario_criador': types.string(),
                'assunto': types.string(),
                'tem_prazo': types.number().int(),
                'data_prazo': types.string().nullable(),
                'status_tarefa': types.number().int(),
            }),
        )
    })

}
