// mensagem de criar um plano de ação
import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class CriarPlanoDeAcao extends CommandMessage{

    name = 'command:sig/iegm/plano-acao/criar'

    input = types.object({
        'cod_entidade': types.string().uuid(),
        'cod_questao_questionario_entidade': types.string().uuid(),
        'assunto': types.string(),
        'data_prazo': types.string(),
        'descricao': types.string(),
        'cod_usuarios_encaminhar': types.array(
            types.object({
                'cod_usuario': types.string().uuid(),
                'tipo_usuario': types.number().int(),
            })
        ),
        'cod_usuarios_responsaveis': types.array(
            types.object({
                'cod_usuario': types.string().uuid(),
                'tipo_usuario': types.number().int(),
            })
        ),
        'subtarefas': types.array(
            types.object({
                'assunto': types.string(),
                'descricao': types.string(),
                'data_prazo': types.string(),
                'cod_usuarios_encaminhar': types.array(
                    types.object({
                        'cod_usuario': types.string().uuid(),
                        'tipo_usuario': types.number().int(),
                    })
                ),
            })
        ).optional()
    })

}