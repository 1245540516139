import { a as n } from "./chunk-RAAYCPUM.js";
function T(...r) {
  return n(d, r);
}
var d = (r, t) => {
  let a = [[], []];
  for (let [o, e] of r.entries()) {
    let y = t(e, o, r);
    a[y ? 0 : 1].push(e);
  }
  return a;
};
export { T as a };