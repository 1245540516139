import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class HistoricoDaQuestaoNaEntidade extends ViewMessage{

    name = 'view:iegm/questionarios/questoes/historico-entidade' 

    input = types.object({
        'cod_questao': types.string().uuid(),
        'cod_entidade': types.string().uuid(),
    })

    output = types.any()

}