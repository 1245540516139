import { EnvironmentInjector, inject, Injectable, Injector } from '@angular/core';
import { Type } from '@iegm-app/core';
import { Observable, switchMap } from 'rxjs';
import { GlobalModalControllerService, ModalProperties } from './global-modal-controller.service';

@Injectable()
export class ModalControllerService {

    protected modalController = inject(GlobalModalControllerService)
    protected injector = inject(Injector)
    protected environmentInjector = inject(EnvironmentInjector)

    resolve<P extends Array<any>>(resolver:(...params:P) => Observable<any>, ...params:P){

        return {
            open: <T>(component_type:Type<T>, properties:ModalProperties) : Observable<T> => {

                return resolver(...params).pipe(
                    switchMap(data => this.open(
                        component_type, 
                        Object.assign(
                            properties,
                            {
                                'resolver':{
                                    'fn': resolver, 
                                    'params':params, 
                                    'value':data
                                }
                            }
                        ),
                    ))
                )
                
            }
        }

    }

    open<T>(component_type:Type<T>, properties:ModalProperties){

        return this.modalController.open(
            component_type, 
            Object.assign(
                {
                    'injector': this.injector,
                    'enviromentInjector': this.environmentInjector,
                }, 
                properties
            )
        )

    }

}
