import { CommonModule } from '@angular/common';
import { Component, ComponentRef, inject, Injector, Input, OnInit, ViewContainerRef, ViewRef } from '@angular/core';
import { UUIDString } from '@iegm-app/core';
import { MessageProperties, MessagesControllerService } from '../../services/messages/messages-controller.service';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'message',
    templateUrl: './message.component.html',
    providers: [],
})
export class MessageComponent implements OnInit{

    protected is_closing = false

    controller = inject(MessagesControllerService)
    injector = inject(Injector)

    @Input() id!:UUIDString
    @Input() index!:number
    @Input() properties!:MessageProperties

    constructor(
    ){
    }

    ngOnInit(){
        this.componentCreated()
        setTimeout(() => this.close(), this.properties.timeout ?? 3500)
    }

    componentCreated(){
        this.controller.events.emit({'event':'created', 'id':this.id, 'component':this})
    }

    close(){

        this.is_closing = true

        setTimeout(() => {
            this.controller.close(this.id)
        }, 250)

    }

    //
    //
    //

    get messageClass(){

        return {
            'closed': this.is_closing,
            [this.properties.color ?? 'is-info']: true
        }

    }

}
