import { a as i } from "./chunk-RAAYCPUM.js";
function p(...e) {
  return i(a, e, o);
}
var a = (e, n) => e.length < n.length ? e.map((t, r) => [t, n[r]]) : n.map((t, r) => [e[r], t]),
  o = e => (n, t) => ({
    hasNext: !0,
    next: [n, e[t]],
    done: t >= e.length - 1
  });
export { p as a };