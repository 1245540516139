import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ExcluirComentarioNaQuestaoDoQuestionarioDaEntidade extends CommandMessage{

    name = 'command:sig/iegm/preenchimento/excluir-comentario'

    input = types.object({
        'cod_comentario_questao_questionario_entidade': types.string(),
    })

}