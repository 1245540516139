export const funcionalidades = {
    MODULO_TAREFAS: 'modulo_tarefas',
    MODULO_MONITORAMENTO: 'modulo_monitoramento',
    MODULO_QUESTIONARIO: 'modulo_questionario',
    MODULO_PREENCHIMENTO: 'modulo_preenchimento',
    MODULO_CONFIGURACOES: 'modulo_configuracoes',
    MENU_CONFIGURACAO: 'menu_configuracao',
    MENU_ACOMPANHAMENTO: 'menu_acompanhamento',
    MENU_REVISAO: 'menu_revisao',
    MENU_CONCLUSAO: 'menu_conclusao',
    CONFIGURAR_DIMENSAO: 'configurar_dimensao',
    CONFIGURAR_QUESTAO: 'configurar_questao',
    ESTORNAR_PREENCHIMENTO_QUESTIONARIO: 'estornar_preenchimento_questionario',
    INICIAR_PREENCHIMENTO_QUESTIONARIO: 'iniciar_preenchimento_questionario',
    RESETAR_QUESTIONARIO: 'resetar_questionario',
    INTERVIR_QUESTAO: 'intervir_questao',
    VALIDAR_QUESTAO: 'validar_questao',
    CONCLUIR_DIMENSAO: 'concluir_dimensao',
    ESTORNAR_CONCLUSAO_DIMENSAO: 'estornar_conclusao_dimensao',
    DEVOLVER_DIMENSAO_REVISAO: 'devolver_dimensao_revisao',
    FINALIZAR_DIMENSAO: 'finalizar_dimensao',
    ESTORNAR_FINALIZACAO_DIMENSAO: 'estornar_finalizacao_dimensao',
    CONCLUIR_QUESTIONARIO: 'concluir_questionario',
    ESTORNAR_CONCLUSAO_QUESTIONARIO: 'estornar_conclusao_questionario',
    RESPONDER_QUESTOES_IEGM: 'responder_questoes_iegm',
    PEDIR_REVISAO_QUESTAO: 'pedir_revisao_questao'
}