import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
    selector: '[downloadAnexo]',
    standalone: true,
})
export class DownloadDirective implements OnInit{

    @Input() downloadAnexo!:Array<string>|string

    ngOnInit(){
        console.log(this.downloadAnexo)
    }

    @HostListener('click', ['$event'])
    onClick($event:PointerEvent){
        console.log(`${environment.api}/download/${this.downloadAnexo[0]}/${this.downloadAnexo[1]}`)
        window.open(`${environment.api}/download/${this.downloadAnexo[0]}/${this.downloadAnexo[1]}`)
    }
    
}
