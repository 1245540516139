import { Directive, ElementRef } from "@angular/core";
import { InputIntDirective } from "./input-int.directive";

@Directive({
    selector: 'input[positive-int],input[positiveInt]',
    standalone: true,
    hostDirectives: [
        {'directive':InputIntDirective, 'inputs':['options', 'max', 'min']},
    ],
})
export class InputPositiveIntDirective{

    constructor(
        private el:ElementRef,
        readonly inputInt:InputIntDirective
    ) {

        this.inputInt.options = {
            'prefix': '',
            'suffix': '',
            'precision': 0,
            'min': 0,
            'allowZero': true,
            'allowNegative': false,
            'decimal': '',
            'thousands': '',
        }

    }

}
