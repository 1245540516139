import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class IniciarQuestionarioDaEntidade extends CommandMessage{

    name = 'view:sig/iegm/questionario-entidade/iniciar'

    input = types.object({
        'cod_entidade': types.string().uuid(),
        'cod_questionario': types.string().uuid(),
    })

    output = types.object({
        'cod_questionario_entidade': types.string().uuid(),
    })

}