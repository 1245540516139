<div class="modal-page">

    <div class="modal-page-content">

        @if(properties.message){
            <div>
                {{properties.message}}
            </div>
        }
        
    </div>

    @if( properties.buttons ){

        <div class="modal-page-footer">

            <div class="buttons is-flex-direction-row-reverse is-justify-content-flex-start">
    
                @for (button of properties.buttons; track $index) {

                    <button [class]="'button is-small ' + ( button.class ?? '' )" (click)="buttonClicked($index)">
                        {{button.label}}
                    </button>

                }
        
            </div>
    
        </div>
    
    }
    
</div>
