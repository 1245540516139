var i = ["	", `
`, "\v", "\f", "\r", " ", "\x85", "\xA0", "\u1680", "\u2000", "\u2001", "\u2002", "\u2003", "\u2004", "\u2005", "\u2006", "\u2007", "\u2008", "\u2009", "\u200A", "\u2028", "\u2029", "\u202F", "\u205F", "\u3000", "\uFEFF"],
  r = ["-", "_", ...i],
  p = new RegExp([`[${r.join("")}]+`, String.raw`(?<=\D)(?=\d)`, String.raw`(?<=\d)(?=\D)`, String.raw`(?<=[a-z])(?=[A-Z])`, String.raw`(?<=[A-Z])(?=[A-Z][a-z])`].join("|"), "u"),
  n = e => e.split(p).filter(({
    length: t
  }) => t > 0);
var C = /[a-z]/u,
  u = {
    preserveConsecutiveUppercase: !0
  };
function m(e, t) {
  return typeof e == "string" ? o(e, t) : s => o(s, e);
}
var o = (e, {
  preserveConsecutiveUppercase: t = u.preserveConsecutiveUppercase
} = {}) => n(C.test(e) ? e : e.toLowerCase()).map((s, a) => `${(a === 0 ? s[0]?.toLowerCase() : s[0]?.toUpperCase()) ?? ""}${t ? s.slice(1) : s.slice(1).toLowerCase()}`).join("");
export { m as a };