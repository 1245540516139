import { ComponentRef, EnvironmentInjector, EventEmitter, inject, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { Type, uuid, UUIDString } from '@iegm-app/core';
import { filter, first, Observable, switchMap, tap } from 'rxjs';
import { ModalContainerComponent } from '../../components/modal/modal-container.component';
import { ResolverOutput } from '../../../libs/resolver';

@Injectable({
    providedIn: 'root',
})
export class GlobalModalControllerService {

    protected rootViewContainerRef!:ViewContainerRef
    protected container?:ComponentRef<ModalContainerComponent>
    protected environmentInjector = inject(EnvironmentInjector)
    protected injector = inject(Injector)

    readonly modals:Array<MotelItemParameters> = []
    readonly events = new EventEmitter<{event:'created'|'closed', id:UUIDString, component?:ComponentRef<any>}>()

    initialize(viewContainerRef:ViewContainerRef){
        this.rootViewContainerRef = viewContainerRef
        this.events.pipe(filter(e => e.event === 'created')).subscribe(e => this.onModalCreated(e.component!))
    }

    resolve<P extends Array<any>>(resolver:(...params:P) => Observable<any>, ...params:P){

        return {
            open: <T>(component_type:Type<T>, properties:ModalProperties) : Observable<T> => {
                return resolver(...params).pipe(
                    switchMap(data => this.open(
                        component_type,
                        Object.assign(
                            properties,
                            {
                                'resolver':{
                                    'fn': resolver,
                                    'params': params,
                                    'value': data
                                }
                            }
                        ),
                    ))
                )
            }
        }

    }

    open<T>(component_type:Type<T>, properties:ModalProperties){

        return new Observable<T>((subscriber) => {

            if( !this.container ){
                this.container = this.rootViewContainerRef.createComponent(ModalContainerComponent)
            }

            const modal_uuid = uuid()

            this.events.pipe(
                first(e => e.event === 'created' && e.id === modal_uuid),
                tap(e => {
                    subscriber.next(e.component!.instance as T)
                    subscriber.complete()
                }),
            ).subscribe()

            this.modals.push({
                'id': modal_uuid,
                'properties': properties,
                'template': component_type,
                'injector': properties.injector ?? this.injector,
                'enviromentInjector': properties.enviromentInjector ?? this.environmentInjector,
            })

        })

    }

    close(id:UUIDString){

        const modal = this.modals.find(m => m.id === id)

        if( !modal ){
            return
        }

        this.modals.splice(this.modals.indexOf(modal), 1)
        this.onModalClosed()

    }

    protected onModalCreated(component:ComponentRef<any>){
    }

    protected onModalClosed(){

        if( this.modals.length === 0 ){
            this.container?.destroy()
            this.container = undefined
        }

    }

}

export type MotelItemParameters = {
    id:UUIDString
    template:any
    instance?:any
    inputs?:Record<string, any>
    properties:ModalProperties
    injector?:any
    enviromentInjector?:any
}

export type ModalProperties<R extends (...params:RP) => Observable<any> = any, RP extends Array<any> = any> = {
    title?:string|false
    size?:'is-small'|'is-medium'|'is-large'|'offcanvas'|'borderless'
    injector?:any
    enviromentInjector?:any
    is_closeable?:boolean
    inputs?:Record<string, any>
    resolver?:{
        fn: R,
        params: RP,
        value?: ResolverOutput<R>
    }
}
