import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { BannerComponent } from './components/banner/banner.component';
import { DropdownItemComponent } from './components/dropdown/dropdown-item.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { InputUploadControlComponent } from './components/inputs/upload/input-upload-control/input-upload-control.component';
import { DownloadDirective } from './directives/download/download.directive';
import { DropdownToggleDirective } from './directives/layout/dropdown-toggle/dropdown-toggle.directive';
import { UCFirstCasePipe } from './pipes/case-transformation/ucfirst.pipe';
import { InputDecimalDirective } from './directives/form/input-decimal.directive';
import { InputIntDirective } from './directives/form/input-int.directive';
import { InputPositiveIntDirective } from './directives/form/input-positive-int.directive';
import { PaginationComponent } from './components/pagination/pagination.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UCFirstCasePipe,
        BannerComponent,
        DropdownComponent,
        DropdownToggleDirective,
        DropdownItemComponent,
        NgxFileDropModule,
        InputUploadControlComponent,
        PaginationComponent,
        DownloadDirective,
        NgxMaskDirective,
        NgxMaskPipe,
        InputDecimalDirective,
        InputIntDirective,
        InputPositiveIntDirective,
    ],
    exports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UCFirstCasePipe,
        BannerComponent,
        DropdownComponent,
        DropdownItemComponent,
        NgxFileDropModule,
        InputUploadControlComponent,
        PaginationComponent,
        DownloadDirective,
        NgxMaskDirective,
        NgxMaskPipe,
        InputDecimalDirective,
        InputIntDirective,
        InputPositiveIntDirective,
    ],
    declarations: [],
    providers: [],
})
export class AppModule { }
