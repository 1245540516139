import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ListaDeQuestoesDoQuestionarioDaEntidade extends ViewMessage{

    name = 'view:iegm/questionarios/questoes-questionario-entidade/lista'

    input = types.object({
        'cod_questionario_entidade': types.string().uuid(),
        'cod_dimensao_questionario_entidade': types.string().uuid().optional(),
        'status_questao': types.array(types.number().int()).optional(),
    })

    output = types.array(
        types.object({
            'cod_dimensao': types.string().uuid(),
            'cod_dimensao_questionario_entidade': types.string().uuid(),
            'nome_dimensao': types.string(),
            //
            'cod_questao': types.string().uuid(),
            'cod_questao_questionario': types.string().uuid(),
            'cod_questao_questionario_entidade': types.string().uuid(),
            'descricao_questao': types.string(),
            'numeracao_questao': types.string(),
            'cod_usuario_responsavel_questao': types.nullable(types.string().uuid()),
            'nome_usuario_responsavel_questao': types.nullable(types.string()),
            //
            'status_questao': types.nullable(types.number().int()),
            'descricao_status_questao': types.nullable(types.string()),
            'cod_usuario_responsavel_status_questao': types.nullable(types.string()),
            'nome_usuario_responsavel_status_questao': types.nullable(types.string()),
            'data_status_questao': types.nullable(types.string()),
            //
            'questao_respondida': types.number().int(),
            'cod_usuario_responsavel_resposta_questao': types.nullable(types.string()),
            'nome_usuario_responsavel_resposta_questao': types.nullable(types.string()),
            'data_resposta_questao': types.nullable(types.string()),
            //
            'tem_plano_acao': types.number().int(),
            'valor_resultado_questao': types.string().nullable(),
            'valor_resultado_questao_ano_anterior': types.string().nullable(),
        })
    )

}
