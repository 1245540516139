import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class DadosDoQuestionarioDaEntidade extends ViewMessage{

    name = 'view:iegm/questionarios/questionario-entidade/dados'

    input = types.object({
        'cod_questionario_entidade': types.string().uuid(),
    })

    output = types.object({
        'cod_questionario': types.string().uuid(),
        'ano': types.number().int(),
        'nome_questionario': types.string(),
        'cod_questionario_entidade': types.string().uuid(),
        'cod_entidade': types.string().uuid(),
        'status_questionario_entidade': types.number().int(),
        'descricao_status_questionario_entidade': types.string(),
        'nome_responsavel_status_questionario_entidade': types.nullable(types.string()),
        'data_status_questionario_entidade': types.nullable(types.string()),
        'cod_responsavel_questionario_entidade': types.string(), 
        'nome_responsavel_questionario_entidade': types.string(),
        //
        'total_dimensoes': types.number().int(),
        'total_dimensoes_concluidas': types.number().int(),
        'total_dimensoes_nao_concluidas': types.number().int(),
        'total_responsaveis_dimensoes_configurados': types.number().int(),
        'total_responsaveis_dimensoes_nao_configurado': types.number().int(),
        'total_responsaveis_questoes_configurados': types.number().int(),
        'total_responsaveis_questoes_nao_configurados': types.number().int(),
        'total_questoes': types.number().int(),
        'total_questoes_concluidas': types.number().int(),
        'total_questoes_nao_concluidas': types.number().int(),
        'total_questoes_validadas': types.number().int(),
        'total_questoes_nao_validadas': types.number().int(),
        'total_dimensoes_finalizadas': types.number().int(),
        'total_dimensoes_nao_finalizadas': types.number().int(),
        //
        'todos_responsaveis_dimensoes_configurados': types.number().int(),
        'todas_dimensoes_finalizadas': types.number().int(),
        'todas_questoes_concluidas': types.number().int(),
        'todas_dimensoes_configuradas': types.number().int(),
        'todas_dimensoes_concluidas': types.number().int(),
        'todos_responsaveis_questoes_configurados': types.number().int(),
    })

}

