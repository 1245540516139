import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, EventEmitter, inject, Input, OnDestroy, OnInit, Output, signal, Signal } from '@angular/core';
import { Pagination } from './pagination.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { IntNumber } from '@iegm-app/core';
import { from, ReplaySubject, takeUntil, tap } from 'rxjs';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnDestroy{

    protected router = inject(Router)
    protected route = inject(ActivatedRoute)
    protected cdr = inject(ChangeDetectorRef)

    protected destroyed$ = new ReplaySubject(1)

    @Input() paginacao!:Pagination
    @Output() alteracao = new EventEmitter()

    clicks = signal(0)

    ngOnInit(){

    }
    
    ngOnDestroy(){
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }

    irParaProximaPagina(){
        this.clicks.update(x => x + 1)
        this.modificarPagina(Number(this.paginacao.pagina_atual) + 1)
    }
    
    irParaPaginaAnterior(){
        this.clicks.update(x => x + 1)
        this.modificarPagina(Number(this.paginacao.pagina_atual) - 1)
    }

    irParaPagina(pagina:IntNumber){
        this.modificarPagina(Number(pagina))
    }

    protected modificarPagina(pagina:IntNumber){

        from(
            this.router.navigate([], {
                'queryParams': {
                    'pagina': pagina
                },
                'queryParamsHandling': 'replace',
                'relativeTo': this.router.routerState.root,
            })
        ).pipe(
            //tap(() => this.alteracao.emit(pagina)),
        ).subscribe()

    }

    /**
     * Fiz tanta gambiara por causa de um erro na custom route dtrategy que nem sei mais
     * depois precisa refazer isso usando signal
     */
    get paginas(){
        
        console.log('computed', this.paginacao.pagina_atual)
        const atual = Number(this.paginacao.pagina_atual)
        const inicio = Math.max(1, atual - 1)
        const termino = Math.min(this.paginacao.total_paginas, atual + 2)
        const retorno = []

        for(let i = inicio; i <= termino; i++){
            retorno.push(i)
        }

        return retorno

    }

}
