import { Injector, inject, runInInjectionContext } from "@angular/core";
import { AuthService } from "./services/auth/auth.service";
import { ApiService } from "./services/http/api.service";
import { MessagesControllerService } from "./services/messages/messages-controller.service";
import { AlertsService } from "./components/alerts/alerts.service";
import { ApiInstance } from "./services/http/api.instance";
import { HttpClient } from "@angular/common/http";

let GLOBAL_INJECTION_CONTEXT:null|Injector = null

export function setGlobalInjectionContext(context:Injector){
    GLOBAL_INJECTION_CONTEXT = context
}

export function api(params?:Record<string, any>){

    if( !GLOBAL_INJECTION_CONTEXT ){
        throw 'error in GLOBAL_INJECTION_CONTEXT'
    }

    return runInInjectionContext(GLOBAL_INJECTION_CONTEXT, () => new ApiInstance(Object.assign(params ?? {}, {'http':inject(HttpClient)})))
    //return runInInjectionContext(GLOBAL_INJECTION_CONTEXT, () => inject(ApiService))

}

export function auth(){

    if( !GLOBAL_INJECTION_CONTEXT ){
        throw 'error in GLOBAL_INJECTION_CONTEXT'
    }

    return runInInjectionContext(GLOBAL_INJECTION_CONTEXT, () => inject(AuthService))

}

export function messages(){

    if( !GLOBAL_INJECTION_CONTEXT ){
        throw 'error in GLOBAL_INJECTION_CONTEXT'
    }

    return runInInjectionContext(GLOBAL_INJECTION_CONTEXT, () => inject(MessagesControllerService))

}

export function alerts(){

    if( !GLOBAL_INJECTION_CONTEXT ){
        throw 'error in GLOBAL_INJECTION_CONTEXT'
    }

    return runInInjectionContext(GLOBAL_INJECTION_CONTEXT, () => inject(AlertsService))

}

export const dialogs = alerts
