import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ResponderQuestaoDoQuestionarioDaEntidade extends CommandMessage{

    name = 'command:sig/iegm/preenchimento/responder'

    input = types.object({
        'cod_questao_questionario_entidade': types.string(),
        'concluir_questao': types.number(),
        'intervencao': types.optional(types.number()),
        'opcoes': types.array(
            types.object({
                'cod_opcao_questionario': types.string(),
                'valor': types.string(),
            })
        ),
    })

}