import { a as o, b as y } from "./chunk-47QIAWAQ.js";
import { b as a } from "./chunk-TZWE5SWO.js";
function s(...r) {
  return a(p, r);
}
function p(r, t, e) {
  if (e <= 0) return [];
  if (e >= r.length) return [...r];
  let n = r.slice(0, e);
  o(n, t);
  let u = r.slice(e);
  for (let i of u) y(n, t, i);
  return n;
}
export { s as a };