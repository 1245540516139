<div 
    class="message" 
    [ngClass]="messageClass" 
    [ngStyle]="{
        '--modal-index': index,
        '--modal-z-index': (controller.messages.length - index - 1),
    }
">

    <div class="message-body">
        <div>
            <strong>{{properties.title}}</strong>
        </div>
        <div>
            {{properties.message}}
        </div>
    </div>

</div>
