import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class AlterarDadosDaQuestaoDoQuestionariosDaEntidade extends CommandMessage{

    name = 'view:sig/iegm/questionario-entidade/questao/alterar'

    input = types.object({
        'cod_questao_questionario_entidade': types.string().uuid(),
        'cod_usuario_responsavel': types.optional(types.string().uuid()),
    })
    
}