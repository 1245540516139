import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ReabrirTarefa extends CommandMessage{

    name = 'command:sig/iegm/tarefa/reabrir'

    input = types.object({
        'cod_tarefa': types.string().uuid(),
        'mensagem': types.string().nullable(),
    })

}