var e = {
    done: !0,
    hasNext: !1
  },
  s = {
    done: !1,
    hasNext: !1
  },
  a = () => e,
  o = t => ({
    hasNext: !0,
    next: t,
    done: !1
  });
export { s as a, a as b, o as c };