function b(t) {
  return (e, r) => {
    if (r === 0) return t(e);
    if (!Number.isInteger(r)) throw new TypeError(`precision must be an integer: ${r}`);
    if (r > 15 || r < -15) throw new RangeError("precision must be between -15 and 15");
    if (Number.isNaN(e) || !Number.isFinite(e)) return t(e);
    let n = 10 ** r;
    return t(e * n) / n;
  };
}
export { b as a };