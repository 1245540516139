import { a as n } from "./chunk-IEE7G5JG.js";
import { a as o } from "./chunk-ANXBDSUI.js";
function y(...r) {
  return n(i, r);
}
var i = (r, t) => e => r.every(a => !t(e, a)) ? {
  done: !1,
  hasNext: !0,
  next: e
} : o;
export { y as a };