import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class CorrigirQuestionarioDaEntidade extends CommandMessage{

    name = 'view:sig/iegm/questionario-entidade/corrigir'

    input = types.object({
        'cod_questionario_entidade': types.string().uuid(),
    })

}