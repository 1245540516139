import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class InserirUsuarioNaEntidade extends CommandMessage{

    name = 'command:sig/entidade/usuario/inserir'

    input = types.object({
        'cod_entidade': types.string(),
        'responsavel_iegm': types.number().int(),
        'nome': types.string(),
        'login': types.string(),
        'senha': types.string(),
    })

    output = types.object({
        'cod_usuario': types.string(),
    })

}