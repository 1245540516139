import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class EncaminharTarefa extends CommandMessage{

    name = 'command:sig/iegm/tarefa/encaminhar'

    input = types.object({
        'cod_tarefa': types.string().uuid(),
        'mensagem': types.string(),
        'cod_usuarios_encaminhar': types.array(
            types.object({
                'cod_usuario': types.string().uuid(),
            })
        ),
    })

    output = types.object({
        'cod_tramite': types.string().uuid(),
    })

}