import { a as t } from "./chunk-SGAFZVQH.js";
import { a as n } from "./chunk-RAAYCPUM.js";
function d(...e) {
  return n(r, e, t(o));
}
var r = ([e]) => e,
  o = () => a,
  a = e => ({
    hasNext: !0,
    next: e,
    done: !0
  });
export { d as a };