import { a as s } from "./chunk-RAAYCPUM.js";
function y(...e) {
  return s(u, e);
}
function u(e, n) {
  if (e === n || Object.is(e, n)) return !0;
  if (typeof e != "object" || typeof n != "object" || e === null || n === null || Object.getPrototypeOf(e) !== Object.getPrototypeOf(n)) return !1;
  if (Array.isArray(e)) return l(e, n);
  if (e instanceof Map) return a(e, n);
  if (e instanceof Set) return c(e, n);
  if (e instanceof Date) return e.getTime() === n.getTime();
  if (e instanceof RegExp) return e.toString() === n.toString();
  if (Object.keys(e).length !== Object.keys(n).length) return !1;
  for (let [r, t] of Object.entries(e)) if (!(r in n) || !u(t, n[r])) return !1;
  return !0;
}
function l(e, n) {
  if (e.length !== n.length) return !1;
  for (let [r, t] of e.entries()) if (!u(t, n[r])) return !1;
  return !0;
}
function a(e, n) {
  if (e.size !== n.size) return !1;
  for (let [r, t] of e.entries()) if (!n.has(r) || !u(t, n.get(r))) return !1;
  return !0;
}
function c(e, n) {
  if (e.size !== n.size) return !1;
  let r = [...n];
  for (let t of e) {
    let o = !1;
    for (let [i, f] of r.entries()) if (u(t, f)) {
      o = !0, r.splice(i, 1);
      break;
    }
    if (!o) return !1;
  }
  return !0;
}
export { y as a };