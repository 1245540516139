import { a as r } from "./chunk-RAAYCPUM.js";
function d(...e) {
  return r(l, e);
}
function l(e, a) {
  let t = {
    ...e
  };
  for (let [n, o] of Object.entries(t)) a(o, n, e) && delete t[n];
  return t;
}
export { d as a };