import { ViewMessage } from "@iegm-app/handlers";
import { types } from "@iegm-app/libs";

export class NotificacoesDoLogin extends ViewMessage{

    name = 'view:sig/auth/login/notificacoes'

    input = types.undefined()

    output = types.array(
        types.object({
            'notificacoes': types.number().int(),
            'tipo': types.string(),
            'subtipo': types.string(),
        })
    )

}