<nav class="pagination" role="navigation" aria-label="pagination">

    @let p = paginas;

    <span *ngIf="p[0] > 1" class="pagination-previous" (click)="irParaPaginaAnterior()">Anterior</span>
    <span *ngIf="paginacao.pagina_atual < paginacao.total_paginas " class="pagination-next" (click)="irParaProximaPagina()">Próximos registros</span>

    <ul class="pagination-list">

        @if( p[0] > 1 ){
            <span class="pagination-link" (click)="irParaPagina(1)">1</span>
            <span class="pagination-ellipsis" *ngIf="p[0]> 2">&hellip;</span>
        }

        @for (pagina of p; track $index) {
            <span 
                [ngClass]="{'pagination-link':true, 'is-current':pagina.toString() === paginacao.pagina_atual.toString()}" 
                (click)="irParaPagina(pagina)"
            >
                {{pagina}}
            </span>
        }
        
        @if( p[ p.length - 1 ] < paginacao.total_paginas ){
            <span class="pagination-ellipsis">&hellip;</span>
            <span class="pagination-link" (click)="irParaPagina(paginacao.total_paginas)">{{paginacao.total_paginas}}</span>
        }

        @if(paginacao.total_registros > 1){
            <span class="ml-2">
                {{paginacao.total_registros}} registros
            </span>
        }

    </ul>
</nav>