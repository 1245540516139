import { a as p } from "./chunk-DH3BPT6T.js";
import { b as i } from "./chunk-TZWE5SWO.js";
var y = (n, e, r) => e < 0 || e >= n.length ? void 0 : T([...n], 0, n.length - 1, e, r);
function T(n, e, r, t, u) {
  if (e === r) return n[e];
  let o = l(n, e, r, u);
  return t === o ? n[t] : T(n, t < o ? e : o + 1, t < o ? o - 1 : r, t, u);
}
function l(n, e, r, t) {
  let u = n[r],
    o = e;
  for (let m = e; m < r; m++) t(n[m], u) < 0 && (p(n, o, m), o += 1);
  return p(n, o, r), o;
}
function d(...n) {
  return i(c, n);
}
var c = (n, e, r) => y(n, r >= 0 ? r : n.length + r, e);
export { d as a };