import { CommandMessage } from '@iegm-app/handlers'
import { types } from '@iegm-app/libs';

export class Login extends CommandMessage{

    name = 'command:auth/login'

    input = types.object({
        'login': types.string(),
        'senha': types.string(),
    })

    output = types.object({
        'cod_usuario': types.string(),
        'login': types.string(),
        'nome': types.string(),
        'cod_entidade': types.string(),
        'jwt': types.string(),
    })

}