import { Injectable, inject } from '@angular/core';
import { UUIDString } from '@iegm-app/core';
import { enums } from '@iegm-app/dictionaries';
import { MessageOutput } from '@iegm-app/handlers';
import { commands, views } from '@iegm-app/iegm-messages';
import { LocalStorageService } from 'ngx-localstorage';
import { map, of, switchMap, tap } from 'rxjs';
import { api } from '../../facades';
import { ApiService } from '../http/api.service';
import { NotificacoesService } from './notificacoes.service';

@Injectable({providedIn: 'root'})
export class AuthService {

    // services
    protected localstorage = inject(LocalStorageService)
    protected readonly api = inject(ApiService)
    protected notificacoes = inject(NotificacoesService)
    protected login_data?:MessageOutput<views.iegm.entidades.DadosDoUsuario> & {jwt:string}
    funcionalidades:Funcionalidades = {} as any

    login(login:string, senha:string){
        
        return this.api.command(commands.auth.Login, {
            login, senha
        }).pipe(
            switchMap(data => this.carregaDadosDoLogin(data.cod_usuario).pipe(map(dados => ({...dados,'jwt': data.jwt})))),
            tap(data => this.login_data = data),
            tap(data => this.atualizaFuncionalidades(data.funcionalidades)),
            tap(data => this.localstorage.set('token', data.jwt))
        )

    }
    
    loginByToken(token?:string|null){

        return of(token).pipe(
            switchMap(token => {
                if(!token) throw 'no login token found'
                return of(token)
            }),
            switchMap(token => api().command(commands.auth.LoginPeloToken, {token})),
            switchMap(data => this.carregaDadosDoLogin(data.cod_usuario).pipe(map(dados => ({...dados,'jwt': data.jwt})))),
            tap(data => this.login_data = data),
            tap(data => this.atualizaFuncionalidades(data.funcionalidades)),
            tap(() => this.fetchNotificacoes())
        )
        
    }

    protected fetchNotificacoes(){
        this.notificacoes.inicializarNotificacoes()
    }

    protected carregaDadosDoLogin(cod_usuario:UUIDString){

        return api().command(views.iegm.entidades.DadosDoUsuario, {
            'cod_usuario': cod_usuario
        })

    }

    protected atualizaFuncionalidades(funcionalidades:Array<string>){

        this.funcionalidades = {} as any

        for (const [index, element] of Object.entries(enums.funcionalidades)) {
            (this.funcionalidades as any)[index] = false
        }

        for (const funcionalidade of funcionalidades) {
            (this.funcionalidades as any)[funcionalidade.toUpperCase()] = true
        }

    }

    logout(){
        
        this.localstorage.clear()
        this.login_data = undefined

        location.href = '/auth/login'

    }

    hasPermission(modulo:NomeDasFuncionalidades){
        return (this.funcionalidades as any)[modulo] ?? false
    }

    //
    //

    get is_logged(){
        return this.login_data?.cod_usuario ? true : false
    }
    
    get dados(){
        
        if( !this.login_data ){
            throw 'user not logged'
        }

        return this.login_data!
        
    }

}

type Funcionalidades = Record<keyof typeof enums.funcionalidades, boolean>
type NomeDasFuncionalidades = keyof typeof enums.funcionalidades
