import { a } from "./chunk-RAAYCPUM.js";
function x(...e) {
  return a(m, e);
}
function m(e, o, i) {
  let t = {};
  for (let [r, n] of e.entries()) {
    let u = o(n, r, e),
      d = i(n, r, e);
    t[u] = d;
  }
  return t;
}
export { x as a };