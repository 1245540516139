import { computed, Injectable, signal } from '@angular/core';
import { IntNumber } from '@iegm-app/core';
import { views } from '@iegm-app/iegm-messages';
import { Subscription, switchMap, tap, timer } from 'rxjs';
import { api } from '../../facades';

@Injectable({providedIn: 'root'})
export class NotificacoesService {

    protected timer?:Subscription
    protected notificacoes = signal({} as Record<string, Record<string, IntNumber>>)

    tem_notificacao_modulo_questionario = computed(() => false)
    tem_notificacao_modulo_preenchimento = computed(() => this.notificacoes()['preenchimento'] && this.notificacoes()['preenchimento']['andamento'] ? true : false)
    tem_notificacao_modulo_tarefas = computed(() => this.notificacoes()['preenchimento'] && this.notificacoes()['tarefas']['andamento'] ? true : false)
    tem_notificacao_modulo_monitoramento = computed(() => false)

    inicializarNotificacoes(){
        
        this.timer = timer(0, 5000).pipe(
            switchMap(() => api({'loading':false}).command(views.auth.NotificacoesDoLogin, undefined).pipe(
                tap(notificacoes => {

                    const buffer:any = {}
                    for (const notificacao of notificacoes) {

                        if( !buffer[notificacao.tipo] ){
                            buffer[notificacao.tipo] = {}
                        }

                        buffer[notificacao.tipo][notificacao.subtipo] = notificacao.notificacoes
                        
                    }

                    this.notificacoes.set(buffer)

                })
            )),
        ).subscribe()

    }

    paraNotificacoes(){
        if( this.timer )
            this.timer.unsubscribe()
    }

}