<div class="modal-page">

    <div class="modal-page-content stack">

        <div class="pb-3 fullfill">

            <div class="field stack">
                <div class="list-group">
                    @for (item of arquivos; track $index) {
                        <div class="list-group-item is-flex">
                            <div class="is-flex-grow-1 link" [downloadAnexo]="[this.tipo_upload, item.cod_upload]">
                                {{item.nome_arquivo}}
                            </div>
                            <div *ngIf="pode_excluir">
                                <button class="button is-outlined is-small is-danger" (click)="removerAnexo(item.cod_upload)">
                                    <i class="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>

    </div>

</div>
