import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class DashboardDasTrefas extends ViewMessage{

    name = 'view:sig/tarefas/dashboard'

    input = types.object({
        'cod_entidade': types.string(),
    })

    output = types.any()

}
