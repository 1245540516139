import { a as t } from "./chunk-RAAYCPUM.js";
function i(...e) {
  return t(o, e);
}
function o(e, u) {
  let n = {};
  for (let [a, r] of Object.entries(e)) {
    let l = u(r, a, e);
    n[a] = l;
  }
  return n;
}
export { i as a };