import { a as t } from "./chunk-RAAYCPUM.js";
function x(...e) {
  return t(y, e);
}
function y(e, o) {
  let r = {};
  for (let [a, n] of e.entries()) {
    let d = o(n, a, e);
    r[d] = n;
  }
  return r;
}
export { x as a };