import { a as t } from "./chunk-RAAYCPUM.js";
function u(...e) {
  return t(i, e);
}
function i(e, a) {
  let n = [];
  for (let [o, r] of e.entries()) {
    if (!a(r, o, e)) break;
    n.push(r);
  }
  return n;
}
export { u as a };