//
// imports
import { ValuesType } from 'utility-types'
import * as lists from './lib/entrypoint'
export * as enums from './lib/entrypoint'

//
// Default exports
//export * from './libs/types';
export { lists as list }

//
// Miscs

type Lists = typeof lists
type Indices = keyof Lists

export function dictionary<T extends Indices, S extends keyof Lists[T]>(list:T, key:S){
    return lists[list][key]
}

export type Dictionary<T extends Indices> = ValuesType<Lists[T]>
export type Dictionaries = Indices