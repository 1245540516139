// editar providencia de tarefa
import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ExcluirProvidenciaTarefa extends CommandMessage{

    name = 'command:sig/iegm/tarefa/excluir-providencia'

    input = types.object({
        'cod_providencia': types.string().uuid(),
    })

}