import { a } from "./chunk-IEE7G5JG.js";
import { a as t } from "./chunk-ANXBDSUI.js";
function c(...r) {
  return a(i, r);
}
var i = (r, n) => o => r.some(e => n(o, e)) ? {
  done: !1,
  hasNext: !0,
  next: o
} : t;
export { c as a };