import { a as n } from "./chunk-RAAYCPUM.js";
function m(...a) {
  return n(o, a, p);
}
var o = (a, e) => a.map(e),
  p = a => (e, r, t) => ({
    done: !1,
    hasNext: !0,
    next: a(e, r, t)
  });
export { m as a };