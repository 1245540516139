import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ApagarUpload extends CommandMessage{

    name = 'command:comum/uploads/apagar'

    input = types.object({
        'cod_upload': types.string(),
        'tipo_upload': types.string(),
    })

}