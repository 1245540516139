import { a as r } from "./chunk-RAAYCPUM.js";
function u(...e) {
  return r(v, e);
}
function v(e, l) {
  if (typeof e != "object" || e === null) return e;
  let n = {
    ...e
  };
  for (let [o, t] of Object.entries(l)) o in n && (n[o] = typeof t == "function" ? t(n[o]) : v(n[o], t));
  return n;
}
export { u as a };