import { Directive, ElementRef } from "@angular/core";
import { NgxCurrencyDirective } from "ngx-currency";

@Directive({
    selector: 'input[decimal]',
    standalone: true,
    hostDirectives: [
        {directive:NgxCurrencyDirective, inputs:['options']}
    ],
})
export class InputDecimalDirective{

    constructor(
        private el:ElementRef,
        readonly ngxCurrencyDirective:NgxCurrencyDirective
    ) {

        this.ngxCurrencyDirective.currencyMask = {
            'prefix': '',
            'suffix': '',
            'precision': 2,
            'allowZero': true,
            'decimal': ',',
            'thousands': '',
            'allowNegative': false,
            'nullable': true,
            'align': 'left',
        }

    }

}
