import { a as t } from "./chunk-RAAYCPUM.js";
function d(...e) {
  return t(s, e);
}
function s(e, n, o) {
  let {
    [n]: K,
    [o]: p
  } = e;
  return {
    ...e,
    [n]: p,
    [o]: K
  };
}
export { d as a };