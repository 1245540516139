function i(o, l) {
  let t = 0,
    e = o.length;
  for (; t < e;) {
    let n = t + e >>> 1,
      d = o[n];
    l(d, n, o) ? t = n + 1 : e = n;
  }
  return e;
}
export { i as a };