import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class AdicionarSubtarefa extends CommandMessage{

    name = 'command:sig/iegm/tarefa/adicionar/subtarefa'

    input = types.object({
        'cod_tarefa_pai': types.string().uuid(),
        'cod_entidade': types.string().uuid(),
        'assunto': types.string(),
        'data_prazo': types.string().nullable(),
        'descricao': types.string(),
        'cod_usuarios_encaminhar': types.array(
            types.object({
                'cod_usuario': types.string().uuid(),
                'tipo_usuario': types.number().int(),
            })
        ),
    })

}