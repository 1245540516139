import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dropdown-item',
    template: `
        <div class="dropdown-item is-flex  pointer">

            @if(icon){
                <div class="icon">
                    <i [class]="icon"></i>
                </div>
            }

            <div class="label">{{label}}</div>

        </div>
    `,
    standalone: true,
    styles: '',
    imports:[CommonModule]
})
export class DropdownItemComponent implements OnInit{

    @Input() icon = ''
    @Input() label!:string

    constructor(){

    }

    ngOnInit(){

    }

}