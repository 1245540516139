import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ListaDeQuestoes extends ViewMessage{

    name = 'view:sig/iegm/preenchimento/lista'

    input = types.object({
        'cod_usuario': types.string(),
        'status_questao': types.array(types.number().int()).optional(),
        'cod_questionario_entidade': types.string().optional(),
        'cod_dimensao': types.string().optional(),
        'pagina': types.number().int().optional(),
    })

    output = types.object({
        'paginacao': types.object({
            'pagina_atual': types.number().int(),
            'total_paginas': types.number().int(),
            'total_registros': types.number().int(),
            'total_registros_pagina': types.number().int(),
        }),
        'filtros': types.object({
            'dimensao': types.array(
                types.object({
                    'id': types.string(),
                    'descricao': types.string(),
                })
            ),
            'questionario': types.array(
                types.object({
                    'id': types.string(),
                    'descricao': types.string(),
                })
            ),
        }),
        'lista': types.array(
            types.object({
                'cod_dimensao': types.string(),
                'cod_dimensao_questionario_entidade': types.string(),
                'nome_dimensao': types.string(),
                'nome_questionario': types.string(),
                'cod_questao_questionario_entidade': types.string(),
                'cod_questao_questionario': types.string(),
                'cod_questao': types.string(),
                'descricao_questao': types.string(),
                'numeracao_questao': types.string(),
                'cod_usuario_responsavel_questao': types.nullable(types.string()),
                'nome_usuario_responsavel_questao': types.nullable(types.string()),
                'status_questao': types.nullable(types.number()),
                'observacao_status_questao': types.nullable(types.string()),
                'descricao_status_questao': types.nullable(types.string()),
                'respondido': types.nullable(types.number()),
                'questao_filho': types.nullable(types.number()),
            })
        )
    })

}