import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class SalvarListaDeVariaveisDoQuestionarioDaEntidade extends ViewMessage{

    name = 'command:iegm/questionarios/questionario-entidade/salvar'

    input = types.object({
        'cod_questionario_entidade': types.string().uuid(),
        'variaveis': types.array(
            types.object({
                'cod_variavel': types.string().uuid(),
                'valor_variavel': types.string().nullable(),
            })
        ),
    })

}
