import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, Input, OnInit, contentChildren } from '@angular/core';
import { DropdownToggleDirective } from '../../directives/layout/dropdown-toggle/dropdown-toggle.directive';
import { DropdownItemComponent } from './dropdown-item.component';

@Component({
    selector: 'dropdown',
    template: `
    <div class="dropdown" [ngClass]="{
        'is-right': direction === 'right',
        'is-left': direction === 'left',
        'is-up': drop === 'up',
        'is-down': drop === 'down',
    }">

        <div class="dropdown-trigger">

            <button [ngClass]="['button', class]" [disabled]="!tem_items_habilitados">
                @if( icon ){
                    <i [class]="icon"></i>
                }
            </button>

        </div>

        <div class="dropdown-menu" role="menu">
            <div class="dropdown-content">
                <ng-content></ng-content>
            </div>
        </div>

    </div>`,
    standalone: true,
    styleUrl: 'dropdown.component.scss',
    imports:[CommonModule, DropdownItemComponent, DropdownToggleDirective]
})
export class DropdownComponent implements OnInit, AfterContentInit{

    @Input() drop:'up'|'down' = 'down'
    @Input() class:string = 'is-small is-ghost'
    @Input() icon:string = ''
    @Input() direction:'right'|'left' = 'left'

    opcoes = contentChildren(DropdownItemComponent)

    constructor(){

    }

    ngAfterContentInit(){

    }

    ngOnInit(){

    }

    //
    //

    get tem_items_habilitados(){
        return this.opcoes().length > 0 ? true : false
    }

}
/*
<div class="dropdown is-active">
  <div class="dropdown-trigger">
    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
      <span>Dropdown button</span>
      <span class="icon is-small">
        <i class="fas fa-angle-down" aria-hidden="true"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu" id="dropdown-menu" role="menu">
    <div class="dropdown-content">
      <a href="#" class="dropdown-item"> Dropdown item </a>
      <a class="dropdown-item"> Other dropdown item </a>
      <a href="#" class="dropdown-item is-active"> Active dropdown item </a>
      <a href="#" class="dropdown-item"> Other dropdown item </a>
      <hr class="dropdown-divider" />
      <a href="#" class="dropdown-item"> With a divider </a>
    </div>
  </div>
</div>
*/