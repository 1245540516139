import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { AlertParameters } from './alerts.service';
import { resolved, ResolvedDataService } from '../../../libs/resolver/resolved-data.service';
import { ModalService } from '../modal/modal.service';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'alert',
    templateUrl: './alert.component.html',
    providers: [ResolvedDataService],
})
export class AlertComponent implements OnInit{

    modal = inject(ModalService)

    @Input() properties!:AlertParameters

    constructor(
    ){
    }

    ngOnInit(){
    }

    buttonClicked(index:number){
        this.properties.buttons![index].action()
        this.modal.close()
    }

}
