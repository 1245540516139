import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class AtualizarUsuarioNaEntidade extends CommandMessage{

    name = 'command:sig/entidade/usuario/atualizar'

    input = types.object({
        'cod_usuario': types.string(),
        'responsavel_iegm': types.optional(types.number().int()),
        'nome': types.optional(types.string()),
        'login': types.optional(types.string()),
        'senha': types.optional(types.string()),
    })

}