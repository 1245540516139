import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, OnInit, inject } from '@angular/core';

@Directive({
    selector: '.dropdown',
    standalone: true,
    hostDirectives: [],
})
export class DropdownToggleDirective implements OnInit{

    protected element:ElementRef<HTMLDivElement> = inject(ElementRef<HTMLDivElement>)
    protected document:Document = inject(DOCUMENT)

    ngOnInit(){
        
        if(this.element.nativeElement.classList.contains('is-active')){
            this.bindMouseOut()
        }

    }

    @HostListener('click', ['$event']) 
    onClick($event:PointerEvent){

        const element = $event.target as HTMLElement

        if( element.classList.contains('dropdown-content') || element.closest('.dropdown-content') ){
            //return
        }

        if( this.element.nativeElement.classList.contains('is-active') ){
            this.element.nativeElement.classList.remove('is-active')
        }else{
            this.element.nativeElement.classList.add('is-active')
            this.bindMouseOut()
        }

        $event.stopImmediatePropagation()
        $event.stopPropagation()
        $event.preventDefault()

    }

    protected bindMouseOut(){

        this.document.addEventListener('click', $event => {
            
            const element = $event.target as HTMLElement

            if( element.closest('.dropdown') ){
                return
            }

            this.element.nativeElement.classList.remove('is-active')

        }, {
            'once': true
        })

    }
    
}