// adicionar providencia de tarefa
import { CommandMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class AdicionarProvidenciaTarefa extends CommandMessage{

    name = 'command:sig/iegm/tarefa/adicionar-providencia'

    input = types.object({
        'cod_tarefa': types.string().uuid(),
        'texto': types.string(),
    })

    output = types.object({
        'cod_providencia': types.string().uuid(),
    })

}