export const tipo_resposta = {
  'boleano': 1,
  'inteiro': 2,
  'numerico': 3,
  'porcentagem': 4,
  'texto': 5,
  'data': 6,
  'horario': 7,
  'data_hora': 8,
  'monetario': 9,
}