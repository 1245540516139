import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class DadosDaDimensaoDoQuestionarioDaEntidade extends ViewMessage{

    name = 'view:iegm/questionarios/dimensoes-questionario-entidade/dados'

    input = types.object({
        'cod_dimensao_questionario_entidade': types.string().uuid(),
    })

    output = types.object({
        // dados da dimensao
        'cod_dimensao': types.string().uuid(),
        'nome_dimensao': types.string(),
        'cod_dimensao_questionario_entidade': types.string().uuid(),
        'status_dimensao_questionario_entidade': types.number().int(),
        'dimensao_configurada': types.number().int(),
        'valor_resultado_dimensao': types.string().nullable(),
        'valor_resultado_dimensao_ano_anterior': types.string().nullable(),
        // status da dimensao
        'cod_usuario_status_dimensao_questionario_entidade': types.nullable(types.string().uuid()),
        'nome_usuario_status_dimensao_questionario_entidade': types.nullable(types.string()),
        'data_status_dimensao_questionario_entidade': types.nullable(types.string()),
        'observacoes_status_dimensao': types.nullable(types.string()),
        // responsavel pela dimensao
        'cod_usuario_responsavel_dimensao_questionario_entidade': types.nullable(types.string().uuid()),
        'nome_usuario_responsavel_dimensao_questionario_entidade': types.nullable(types.string()),
        // detalhes da dimensao
        'total_questoes': types.number().int(),
        'total_questoes_concluidas': types.number().int(),
        'total_questoes_nao_concluidas': types.number().int(),
        'total_questoes_validadas': types.number().int(),
        'total_questoes_nao_validadas': types.number().int(),
        'porcentagem_conclusao': types.number(),
        'total_questoes_disponiveis': types.number().int(),
        'todas_questoes_concluidas': types.number().int(),
        'todas_questoes_validadas': types.number().int(),
        'todas_questoes_finalizadas': types.number().int(),
        // historico
        'historico_status': types.array(
            types.object({
                'data': types.string(),
                'status': types.number().int(),
                'ativo': types.number().int(),
                'descricao_status': types.string(),
                'observacoes': types.nullable(types.string()),
                'cod_usuario': types.string().uuid(),
                'nome_usuario': types.string(),
            })
        )
    })

}

