import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'banner',
    templateUrl: './banner.component.html',
    providers: [],
})
export class BannerComponent implements OnInit{

    @Input() class?:string = ''
    @Input() icon?:string
    @Input() message?:string

    constructor(
    ){
    }

    ngOnInit(){
    }

}
