import { ViewMessage } from "@iegm-app/handlers"
import { types } from "@iegm-app/libs"

export class ListaDeUsuariosDaEntidade extends ViewMessage{

    name = 'view:sig/iegm/entidade/usuarios/lista'

    input = types.object({
        'cod_entidade': types.string().uuid(),
    })

    output = types.array(
        types.object({
            'cod_usuario': types.string().uuid(),
            'nome_usuario': types.string(),
            'login': types.string(),
            'responsavel_iegm': types.number().int(),
        })
    )

}